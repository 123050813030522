import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import dogIllustration from '../images/dog-illustration.svg'

const AboutPage = () => (
	<Layout>
		<SEO
			title="About"
			keywords={[
				`websites`,
				`webapps`,
				`webapps guatemala`,
				`react`,
				`unbreakable`,
				`studio`,
				`serverless`,
				`guatemala`,
				`node`,
				`node guatemala`,
				`serverless guatemala`,
				`go guatemala`,
				`react guatemala`,
				`gatsby guatemala`
			]}
		/>
	</Layout>
);

export default AboutPage;
